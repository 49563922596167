import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { getAnalytics } from 'decentraland-dapps/dist/modules/analytics'
import { Footer } from 'decentraland-ui/dist/components/Footer/Footer'

import Navbar from '../Navbar'

export default function Layout({ children }: { children: React.ReactNode }) {
  const analytics = getAnalytics()
  const location = useLocation()

  useEffect(() => {
    analytics?.page()
  }, [analytics, location])

  return (
    <div className="app">
      <Navbar />
      {children}
      <Footer isFullWidth />
    </div>
  )
}
